export const isRangeOption = (option) => {
    return option.match(/^[0-9\-&~]+$/);
};
export const getCounterRange = (option) => {
    const range = [];
    const counter = option.split("&");
    for (const c of counter) {
        if (c.includes("~")) {
            const [start, end] = c
                .split("~")
                .map((e) => (e === "" ? 0 : parseInt(e)));
            range.push([start, end]);
        }
        else {
            const start = parseInt(c);
            range.push([start, start]);
        }
    }
    return range;
};
export const inRange = (range, num, size) => {
    for (const [_start, _end] of range) {
        const start = _start < 0 ? size + _start : _start;
        const end = _end <= 0 ? size + _end : _end;
        if (start <= num && num <= end) {
            return true;
        }
    }
    return false;
};
